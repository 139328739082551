import React from 'react';
import classnames from 'classnames';

import { IImage, ILink } from 'utils/types';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { SliceHero } from 'components/hero/SliceHero';
import { Buttons } from 'components/buttons/Buttons';
import { Button } from 'components/button/Button';

import s from './Text.module.scss';

type Props = {
  tagline?: string;
  title?: React.ReactNode;
  isSlice?: boolean;
  isSplitPage?: boolean;
  isArticle?: boolean;
  isEvent?: boolean;
  isProduct?: boolean;
  text: React.ReactNode;
  colCount?: number;
  hasLayout?: boolean;
  image?: IImage;
  children?: React.ReactNode;
  color?: 'brand' | 'white' | 'dark' | string;
  link?: ILink;
  label?: string;
  titleTag?: 'h1' | 'h2';
};

export const Text = ({
  tagline,
  title,
  text,
  isSlice,
  isSplitPage,
  isArticle,
  isEvent,
  colCount = 1,
  hasLayout = true,
  isProduct = false,
  image,
  children,
  color,
  link,
  label,
  titleTag = 'h2',
}: Props) => {
  const hasImage = !!image;

  const classes = classnames(s.text, s[color as string], {
    [s.split]: isSplitPage,
    [s.article]: isArticle,
    [s.product]: isProduct,
    [s.full]: !isSplitPage && !isArticle && !hasImage,
    [s.twocol]: colCount === 2,
    [s.threecol]: colCount === 3,
    [s.hasImage]: hasImage,
  });

  const buttonColor = () => {
    switch (color) {
      case 'brand':
        return 'dark';
      case 'white':
      case 'dark':
        return 'brand';
    }
  };

  const hoverColor = () => {
    switch (color) {
      case 'brand':
        return 'white';
      case 'white':
        return 'dark';
      case 'dark':
        return 'white';
    }
  };

  const content = (
    <>
      <div className={s.text__copy}>{text || children}</div>
      {link && (
        <div className={s.text__buttons}>
          <Buttons>
            <Button
              link={link}
              color={hasImage ? buttonColor() : undefined}
              hover={hasImage ? hoverColor() : undefined}
            >
              {label || 'Read more'}
            </Button>
          </Buttons>
        </div>
      )}
    </>
  );

  const layout = (
    <div className={s.text__row}>
      <div className={s.text__layout}>
        <SliceHero
          title={title}
          tagline={tagline}
          underline={false}
          image={image}
          color={color}
          titleTag={titleTag}
        />
        <div className={s.text__row}>
          <div className={s.text__indent}>{content}</div>
        </div>
      </div>
    </div>
  );

  if (isEvent) {
    return <div className={classes}>{content}</div>;
  }

  if (!isSlice) {
    return <div className={classes}>{hasLayout && !isProduct ? layout : content}</div>;
  }

  return (
    // <BackgroundSection color={!hasImage ? color : undefined}>
    <BackgroundSection className={classes}>
      <div className={s.text__segment}>
        <div className={s.text__container}>{hasLayout ? layout : content}</div>
      </div>
    </BackgroundSection>
  );
};
