import React from 'react';
import classnames from 'classnames';
import Image from 'next/image';

import { IImage } from 'utils/types';
import { Tagline } from 'components/tagline/Tagline';

import s from './SliceHero.module.scss';
import { Caption } from 'components/media/Caption';

type Props = {
  isSlice?: boolean;
  title?: React.ReactNode;
  tagline?: string;
  underline?: boolean;
  image?: IImage;
  caption?: React.ReactNode;
  titleTag?: 'h1' | 'h2';
  color?: 'brand' | 'white' | 'dark' | 'none' | string;
  flush?: boolean;
  alt?: boolean;
};

export const SliceHero = ({
  isSlice,
  title,
  tagline,
  underline = true,
  image,
  caption,
  titleTag = 'h2',
  color = 'none',
  flush = false,
}: Props) => {
  const hasTitle = !!title;
  const hasTagline = tagline && tagline.length > 0;
  const hasImage = !!image;
  const Tag = (titleTag || 'h2') as any;

  if (!hasTitle && !hasImage) {
    return null;
  }

  const content = (
    <div className={s.hero__header}>
      {image && (
        <>
          <figure className={s.hero__image}>
            <Image
              src={image.desktop?.url || image.url}
              layout="fill"
              objectFit="cover"
              className={s.hero__img}
              alt=""
              role="presentation"
              sizes="(min-width: 1280px) 1280px, 100vw"
            />
            {caption && <Caption className={s.hero__caption} caption={caption} />}
          </figure>
        </>
      )}
      {hasTitle && (
        <div className={s.hero__heading}>
          {hasTagline && <Tagline text={tagline} />}
          {hasTitle && (
            <>
              {typeof title === 'string' ? (
                <Tag className={s.hero__title}>{title}</Tag>
              ) : (
                <div className={s.hero__titleWrap}>{title}</div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );

  const container = (
    <div className={s.hero__segment}>
      <div className={s.hero__container}>
        <div className={s.hero__row}>
          <div className={s.hero__col}>{content}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={classnames(s.hero, s[color], {
        [s.hasImage]: hasImage,
        [s.underline]: hasTitle && underline && (!hasTagline || hasImage),
        [s.flush]: flush,
      })}
    >
      {isSlice ? container : content}
    </div>
  );
};
